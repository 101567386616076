@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Archivo Black', sans-serif;
  border-color: var(--text-primary);
  color: var(--text-primary);
  background-color: var(--background);
}

[theme='light'] {
  --background: white;
  --text-primary: black;
}

body {
  cursor: url('../public/images/cursors/default.png'), auto;
}

/* Hover cursor for links and buttons */
a:hover,
button:hover {
  cursor: url('../public/images/cursors/hover.png'), pointer;
}

/* Click (active) cursor for links and buttons */
a:active,
button:active {
  cursor: url('../public/images/cursors/click.png'), pointer;
}
